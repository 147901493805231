import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { RootState } from "..";
import { ArtworkData, ArtworkMediaType, ArtworkUploadStatus, checkIfCorrectSculptureMedia, defaultSculptureImageFileFormat, validSculptureFileFormats, validSculptureImageFileFormats, validVideoFileFormats } from "../../types/FloorPlan/Artwork.types";
import { callApi, callApiFile } from "../api";
import { changeSculptureFileFormat, checkArtworkStatus, getArtworksRequest, setArtworks, updateArtwork } from "../reducers/Artworks";
import { uploadArtwork } from "../reducers/FloorPlan/FloorPlan";
import { isSuccessResponse } from "../utils";
import missingImage from './../../assets/img/missing-image.png'
import { SculptureFileFormat } from "../../types/FloorPlan/Sculpture.types";

const defaultPhoto = {
    fileName: missingImage,
    original: missingImage,
    large: missingImage,
    medium: missingImage,
    small: missingImage,
}

export function* doGetExhibitionArworks({ payload: uuid }: PayloadAction<string>) {
    // console.log("doGetExhibitionArworks", uuid)
    try {
        const artworksResponse: AxiosResponse = yield call(callApi, 'GET', `/exhibitions/${uuid}/artworks`)
        if (!isSuccessResponse(artworksResponse, true)) {
            console.error(artworksResponse)
            throw new Error('Unable to get exhibition.')
        }

        // console.log("artworksResponse", JSON.stringify(artworksResponse.data))
        const artworksRaw = artworksResponse.data.data

        const artworks: { [id: string]: ArtworkData } = {}

        for (let i = 0; i < artworksRaw.length; i++) {
            const item = artworksRaw[i]

            var media = null
            if (item.media_type === "SCULPTURE" && item.sculpture)
                media = {
                    name: item.sculpture.file_name,
                    uuid: item.sculpture.uuid,
                    status: item.sculpture.upload_status
                }

            if (item.media_type === "VIDEO" && item.video)
                media = {
                    name: item.video.file_name,
                    uuid: item.video.uuid,
                    status: item.video.upload_status
                }

            artworks[item.uuid] = {
                uuid: item.uuid,

                title: item.title,
                width: item.width,
                height: item.height,
                depth: item.depth,
                unit: item.unit,
                year: item.year,
                price: item.price,
                currency: item.currency,
                mediaType: item.media_type,
                artist: {
                    firstName: item.artist.firstname,
                    lastName: item.artist.lastname,
                    web: item.artist.web,
                },
                description: item.description,
                photo: item.photo ? {
                    fileName: item.photo.file_name,
                    original: item.photo.original,
                    large: item.photo.large,
                    medium: item.photo.medium,
                    small: item.photo.small,
                } : defaultPhoto,
                media,
                sculptureFormat: item.sculpture_format || "Sculpture",
            }
            checkIfCorrectSculptureMedia(artworks[item.uuid])
        }


        // console.log("set artworks", artworks)
        yield put(setArtworks(artworks))

    } catch (error) {
        if (error instanceof Error) {
            console.error(error.message)
        } else {
            // console.log('Unexpected error', error);
        }/* 
        yield put(openNotification('An error occurred when getting exhibition map.', 'error'));
        yield put(errorAction(type, error)); */
    } finally {
        // yield put(successAction(type));
    }
}


export function* watchGetArtworks() {
    yield takeLatest(getArtworksRequest, doGetExhibitionArworks);
}

function* setUploadState(artworkUuid: string, artworkData: ArtworkData, status: ArtworkUploadStatus, error?: string) {
    const artworkDataCopy = { ...artworkData }
    if (artworkDataCopy.media) {
        artworkDataCopy.media = {
            ...artworkDataCopy.media,
            status,
            error
        }
        yield put(updateArtwork({ id: artworkUuid, data: artworkDataCopy }))
    }
}

function* doUploadArtwork({ payload }: PayloadAction<{ artworkUuid: string, file: File }>) {
    const { artworkUuid, file } = payload
    if (!file || file == null)
        return


    if (!artworkUuid || artworkUuid === null || artworkUuid === "") {
        console.error("no artwork_uuid given")
        return
    }

    const artworkData: ArtworkData = yield select((state: RootState) => state.artworks.artworks[artworkUuid])
    const type = artworkData?.mediaType
    if (type === "IMAGE" || !!!type) return

    yield setUploadState(artworkUuid, artworkData, "upload_pending")

    try {
        // console.log("doUploadSculpture", artworkUuid)

        const { size, name } = file
        if (size > 70 * 1024 * 1024) {
            console.error('Artwork size must be under 70Mb.')
            yield setUploadState(artworkUuid, artworkData, "upload_failed", "Artwork size must be under 70Mb.")
            return
        }


        const split = name.split('.');
        const fileFormat = split[split.length - 1].toLowerCase();
        const validFormats = type === "SCULPTURE" ? [...validSculptureFileFormats, ...validSculptureImageFileFormats] : validVideoFileFormats
        if (!validFormats.includes(fileFormat)) {
            const formats = validFormats.join(', .');
            const errorMessage = 'Invalid file.\n Valid artwork formats are: .' + formats
            console.error(errorMessage)

            yield setUploadState(artworkUuid, artworkData, "upload_failed", errorMessage)
            return
        }

        var endpoint = type === "SCULPTURE" ? "/sculptures" : "/videos"
        const uploadSculptureRes: AxiosResponse = yield call(callApiFile, 'POST', endpoint, { mediaType: type, file })

        if (!isSuccessResponse(uploadSculptureRes, true)) {
            console.error(uploadSculptureRes)
            yield setUploadState(artworkUuid, artworkData, "upload_failed", "Unable to upload artwork")
            return
        }

        const data: {
            file_name: string,
            uuid: string,
            file_url: string,
            id_accound: number,
            upload_status: ArtworkUploadStatus,
        } | undefined = uploadSculptureRes.data?.data
        if (!data) {
            yield setUploadState(artworkUuid, artworkData, "upload_failed", "Unable to upload artwork")
            return
        }
        const mediaUuid = data.uuid
        if (!mediaUuid) {
            yield setUploadState(artworkUuid, artworkData, "upload_failed", "Unable to upload artwork")
            return
        }

        endpoint = type === "SCULPTURE" ? "/sculpture" : "/video"
        const res: AxiosResponse = yield call(callApi, 'PUT', "/artworks/" + artworkUuid + endpoint, {
            uuid: mediaUuid
        })

        if (!isSuccessResponse(res, true)) {
            console.error(res)
            throw new Error('Unable to assign sculpture')
        }

        const artworkDataCopy = { ...artworkData }


        if (type === "SCULPTURE") {
            const previousFormat = artworkDataCopy.sculptureFormat

            // uploading image
            // previous format is scultpure
            if (validSculptureImageFileFormats.includes(fileFormat) && artworkDataCopy.sculptureFormat === "Sculpture") {
                // change to default sculpture image format
                artworkDataCopy.sculptureFormat = defaultSculptureImageFileFormat
            }

            // uploading sculpute
            // previous format is not scultpure
            if (validSculptureFileFormats.includes(fileFormat) && artworkDataCopy.sculptureFormat !== "Sculpture") {
                // change to default sculpture format
                artworkDataCopy.sculptureFormat = "Sculpture"
            }

            if (previousFormat !== artworkDataCopy.sculptureFormat)
                yield (put(changeSculptureFileFormat({ artworkUuid, sculptureFormat: artworkDataCopy.sculptureFormat })))
        }

        artworkDataCopy.media = {
            name: data.file_name,
            uuid: data.uuid,
            status: data.upload_status
        }

        yield put(updateArtwork({ id: artworkUuid, data: artworkDataCopy }))
        yield put(checkArtworkStatus({ artworkUuid, mediaUuid, type }))

    } catch (error: any) {
        console.error(error)
        yield setUploadState(artworkUuid, artworkData, "upload_failed", "Unable to upload artwork")
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            return error.response;
        }
        return error;
    }
}

export function* watchUploadSculpture() {
    yield takeLatest(uploadArtwork, doUploadArtwork)
}

function* doCheckArtworkStatus({ payload }: PayloadAction<{ type: ArtworkMediaType, artworkUuid: string, mediaUuid: string }>) {
    const { type, artworkUuid, mediaUuid } = payload
    if (type === "IMAGE")
        return
    // console.log("CheckArtworkStatus")

    const artworkData: ArtworkData = yield select((state: RootState) => state.artworks.artworks[artworkUuid])
    const artworkDataCopy = { ...artworkData }
    if (!artworkDataCopy || !artworkDataCopy.media) {
        console.error("could not find artwork", artworkUuid)
        return
    }
    const currentUploadStatus = artworkDataCopy.media.status

    const uri = type === "SCULPTURE" ? `/sculptures/${mediaUuid}` : `/videos/${mediaUuid}`
    const artworksResponse: AxiosResponse = yield call(callApi, 'GET', uri)

    if (currentUploadStatus === "upload_failed" || currentUploadStatus === "uploaded") return

    if (!isSuccessResponse(artworksResponse)) {
        console.error(artworksResponse)
        throw new Error('Unable to get exhibition.')
    }
    // console.log("mediaResponse", artworksResponse.data.data)

    const response: {
        file_name: string,
        file_url: string,
        upload_status: ArtworkUploadStatus
        uuid: string
    } = artworksResponse.data.data

    // update artwork
    if (currentUploadStatus !== response.upload_status) {
        artworkDataCopy.media = {
            name: response.file_name,
            uuid: response.uuid,
            status: response.upload_status
        }
        yield put(updateArtwork({ id: artworkUuid, data: artworkDataCopy }))
    }


    if (response.upload_status === "upload_pending" || response.upload_status === "uploading") {
        yield delay(500)
        yield put(checkArtworkStatus({ artworkUuid, mediaUuid, type }))
    }



    /* 
        const artworkData: ArtworkData = yield select((state: RootState) => state.artworks.artworks[artworkUuid])
        const artworkDataCopy = { ...artworkData }
    
        if (currentUploadStatus === "upload_failed") {
            yield put(updateArtwork({ id: artworkUuid, data: artworkDataCopy }))
            if (artworkDataCopy.sculpture)
                artworkDataCopy.sculpture.status = ""
        } */
}

export function* watchCheckArtworkStatus() {
    yield takeLatest(checkArtworkStatus, doCheckArtworkStatus)
}



function* doChangeSculptureFileFormat({ payload }: PayloadAction<{ artworkUuid: string, sculptureFormat: SculptureFileFormat }>) {
    const { artworkUuid, sculptureFormat } = payload
    const artworkData: ArtworkData = yield select((state: RootState) => state.artworks.artworks[artworkUuid])
    try {
        const artworkDataCopy = { ...artworkData }
        artworkDataCopy.sculptureFormat = sculptureFormat
        checkIfCorrectSculptureMedia(artworkDataCopy)

        // console.log("¿UPDATING ARTWORK", artworkUuid)
        yield put(updateArtwork({ id: artworkUuid, data: artworkDataCopy }))
        yield call(callApi, 'PUT', "/artworks/" + artworkUuid, {
            sculpture_format: sculptureFormat,
        })
    }
    catch (e) {
        console.error(e)
        yield put(updateArtwork({ id: artworkUuid, data: artworkData }))
    }
}
export function* watchChangeSculptureFileFormat() {
    yield takeLatest(changeSculptureFileFormat, doChangeSculptureFileFormat)
}