import { PayloadAction } from "@reduxjs/toolkit";
import { put, select, takeLatest } from "redux-saga/effects";
import { RootState } from "..";
import { SelectedData } from "../../types/Editor.types";
import { ToolType } from "../../types/Editor/Tool.types";
import { FloorPlanDataType, FloorPlanMap } from "../../types/FloorPlan/FloorPlan.types";
import { FloorPlanData } from "../../types/FloorPlan/FloorPlanElement.types";
import { Light } from "../../types/FloorPlan/Light.types";
import { Spot } from "../../types/FloorPlan/Spot.types";
import { Wall } from "../../types/FloorPlan/Wall.types";
import { createAndAddNewFloorPlanData, duplicateSelection, setSelectData, setTool, setToolSuccess } from "../reducers/Editor";
import { addFloorPlanData, cleanUpUnplacedData, computePropertyWarning, setPropertyX } from "../reducers/FloorPlan/FloorPlan";
import { createNewFloorPlanData } from "./FloorPlan.sagas";

function* _createAndAddNewFloorPlanData<T extends FloorPlanData>(dataType: FloorPlanDataType, id?: string) {
    const floorPlanData: T = yield createNewFloorPlanData(dataType, id)

    if (!floorPlanData)
        throw new Error("Couldn't create new data for type " + dataType)

    // console.log("creating and adding floor plan data", dataType, id)
    floorPlanData.placed = false
    yield put(addFloorPlanData({ data: floorPlanData, id: floorPlanData.mapRefUUID }))

    return floorPlanData
}

function* doCreateAndAddNewFloorPlanData({ payload }: PayloadAction<{ type: FloorPlanDataType, id?: string }>) {
    yield _createAndAddNewFloorPlanData(payload.type, payload.id)
}

export function* watchCreateAndAddNewFloorPlanData() {
    yield takeLatest(createAndAddNewFloorPlanData, doCreateAndAddNewFloorPlanData)
}


function* setToolEffect({ payload: toolType }: PayloadAction<ToolType>) {
    // console.log("setToolEffect", toolType)
    const prTool: ToolType = yield select((state: RootState) => state.editor.currentTool.type)

    // console.log("setTool", toolType)

    if (prTool === toolType)
        return

    // console.log("prtool", prTool, "new", toolType)

    yield put(setSelectData([]))

    // Cleanup not placed data
    switch (prTool) {
        case "Spots":
            yield put(cleanUpUnplacedData("Spot"))
            break
        case "Lights":
            yield put(cleanUpUnplacedData("Light"))
            break
        case "Sculptures":
            yield put(cleanUpUnplacedData("Sculpture"))
            break
        case "DrawWalls":
            yield put(cleanUpUnplacedData("Wall"))
            break
    }

    // Change to new tool

    yield put(setToolSuccess({ type: toolType }))

    switch (toolType) {
        case "Spots":
            const newSpot: Spot = yield _createAndAddNewFloorPlanData("Spot")
            // console.log("new spot :", newSpot.mapRefUUID)
            yield put(setSelectData([{ type: "Spot", id: newSpot.mapRefUUID }]))
            break
        case "Lights":
            const newLight: Light = yield _createAndAddNewFloorPlanData("Light")
            yield put(setSelectData([{ type: "Light", id: newLight.mapRefUUID }]))
            break
        case "DrawWalls":
            const newWall: Wall = yield _createAndAddNewFloorPlanData("Wall")
            yield put(setSelectData([{ type: "Wall", id: newWall.mapRefUUID }]))
            break
        case "Entrance":
            yield put(setSelectData([{ type: "Entrance", id: "Entrance" }]))
            break

    }

}

export function* watchSetToolEffect() {
    yield takeLatest(setTool, setToolEffect)
}


function* doDuplicateSelection() {
    const selectedData: SelectedData[] = yield select((state: RootState) => state.editor.selectedData)
    const floorPlanData: FloorPlanMap | null = yield select((state: RootState) => state.floorPlan.floorPlan)

    const newSelectedData: SelectedData[] = []

    if (!floorPlanData)
        return

    for (let i = 0; i < selectedData.length; i++) {
        const { type, id } = selectedData[i];
        switch (type) {
            case "Spot":
                const spot: Spot = yield createNewFloorPlanData("Spot")
                const spotSource = floorPlanData.spots[id]

                if (!spotSource)
                    return

                spot.artworkUUID = spotSource.artworkUUID
                spot.orientation = spotSource.orientation
                spot.type = spotSource.type
                spot.x = spotSource.x
                spot.y = spotSource.y

                spot.placed = false

                yield put(addFloorPlanData({ data: spot, id: spot.mapRefUUID }))
                newSelectedData.push({ id: spot.mapRefUUID, type: "Spot" })
                break
            case "Light":
                const light: Light = yield createNewFloorPlanData("Light")
                const lightSource = floorPlanData.lights[id]

                if (!lightSource)
                    return

                light.color = lightSource.color
                light.intensity = lightSource.intensity
                light.openingAngle = lightSource.openingAngle
                light.orientation = lightSource.orientation
                light.x = lightSource.x
                light.y = lightSource.y

                light.placed = false

                yield put(addFloorPlanData({ data: light, id: light.mapRefUUID }))
                newSelectedData.push({ id: light.mapRefUUID, type: "Light" })
                break
            case "Wall":
                const wall: Wall = yield createNewFloorPlanData("Wall")
                const wallSource = floorPlanData.walls[id]

                if (!wallSource)
                    return

                wall.color = wallSource.color
                wall.colorBack = wallSource.colorBack
                wall.hasColorBack = wallSource.hasColorBack
                wall.hasTexture = wallSource.hasTexture
                wall.textureURL = wallSource.textureURL
                wall.thickness = wallSource.thickness
                wall.region = wallSource.region
                // wall.width = wallSource.width
                // wall.angle = wallSource.angle
                /* 
                                wall.origin = wallSource.origin
                                wall.destiny = wallSource.destiny */

                wall.x = wallSource.destiny.x
                wall.y = wallSource.destiny.y

                // console.log(wallSource, wall, wallSource.angle, wall.angle)
                wall.computePointsFromProperties()
                // wall.computePropertiesFromPoints()

                wall.placed = false

                yield put(addFloorPlanData({ data: wall, id: wall.mapRefUUID }))
                newSelectedData.push({ id: wall.mapRefUUID, type: "Wall" })
                break
        }
    }

    yield put(setSelectData(newSelectedData))
}

export function* watchDuplicateSelection() {
    yield takeLatest(duplicateSelection, doDuplicateSelection)
}


function* selectDataEffect({ payload }: PayloadAction<SelectedData[]>) {
    // console.log("set select dataeffect", payload)
    for (let i = 0; i < payload.length; i++) {
        const data = payload[i];
        switch (data.type) {
            case "Light":
                yield put(computePropertyWarning({ id: data.id, type: data.type }))
        }
    }
}

export function* watchSetSelectData() {
    yield takeLatest(setSelectData, selectDataEffect)
}
