import { CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { ArtworkFrameMatterial } from "../../../types/FloorPlan/Artwork.types";
import { Entrance } from "../../../types/FloorPlan/Entrance.types";
import { FloorPlanDataType, FloorPlanMap, FloorPlanState, MAX_GROUND_REGIONS } from "../../../types/FloorPlan/FloorPlan.types";
import { ComponentOrientations } from "../../../types/FloorPlan/OrientableComponent.types";
import { SpotTypesEnum } from "../../../types/FloorPlan/Spot.types";
import { SimplePoint, Wall, WallSide } from "../../../types/FloorPlan/Wall.types";

function rightAngleConditionX(thisPoint: SimplePoint, otherPoint: SimplePoint) {
    return (Math.abs(thisPoint.x - otherPoint.x) <= (Math.abs(thisPoint.y - otherPoint.y)))
}

function mapWallsToWallPos(wall: Wall) {
    return {
        x1: wall.origin.x,
        y1: wall.origin.y,
        x2: wall.destiny.x,
        y2: wall.destiny.y,
    }
}
function mapWallsToWallsPos(walls: { [id: string]: Wall }) {
    return Object.values(walls).map(w => {
        return {
            x1: w.origin.x,
            y1: w.origin.y,
            x2: w.destiny.x,
            y2: w.destiny.y,
        }
    })
}

function updateAllLightsWarningFromWall(floorPlanMap: FloorPlanMap, wall: Wall) {
    const lights = Object.values(floorPlanMap.lights)
    for (let i = 0; i < lights.length; i++) {
        const light = lights[i];
        light.checkIfItsTooCloseToWallsAndUpdateWarning([mapWallsToWallPos(wall)])
    }
}
export function updateAllLightsWarning(floorPlanMap: FloorPlanMap) {
    const lights = Object.values(floorPlanMap.lights)
    const walls = Object.values(floorPlanMap.walls)
    for (let i = 0; i < lights.length; i++) {
        var tooCloseToWall = false
        for (let j = 0; j < walls.length; j++) {
            if (tooCloseToWall)
                continue

            if (lights[i].checkIfItsTooCloseToWall(mapWallsToWallPos(walls[j])))
                tooCloseToWall = true
        }
        if (tooCloseToWall)
            lights[i].warning = true
    }
    return floorPlanMap
}

export const caseSetPropertyPlaced: CaseReducer<
    FloorPlanState,
    PayloadAction<{
        type: FloorPlanDataType, id: string, placed: boolean,
    }>
> = (state, { payload }) => {
    const { type, id, placed } = payload

    if (!state.floorPlan)
        return

    state.saveState = "CHANGES_TO_SAVE"

    switch (type) {
        case "Light":
            const light = state.floorPlan.lights[id]
            if (!light) {
                console.warn("no light with id " + id + " found")
                return
            }

            light.placed = placed
            state.floorPlan.lights = {
                ...state.floorPlan.lights,
                [id]: light
            }
            break
        case "Spot":
            const spot = state.floorPlan.spots[id]
            if (!spot) {
                console.warn("no light with id " + id + " found")
                return
            }

            spot.placed = placed
            state.floorPlan.spots = {
                ...state.floorPlan.spots,
                [id]: spot
            }
            break
        case "Wall":
            const wall = state.floorPlan.walls[id]
            if (!wall) {
                console.warn("no light with id " + id + " found")
                return
            }

            wall.placed = placed
            state.floorPlan.walls = {
                ...state.floorPlan.walls,
                [id]: wall
            }
            break
        case "Sculpture":
            const sculpture = state.floorPlan.sculptures[id]
            if (!sculpture) {
                console.warn("no sculpture with id " + id + " found")
                return
            }

            sculpture.placed = placed
            state.floorPlan.sculptures = {
                ...state.floorPlan.sculptures,
                [id]: sculpture
            }
            break
        default:
            console.warn("caseSetPropertyPlaced " + type + " not developed")

    }
}

export const caseSetPropertyName: CaseReducer<
    FloorPlanState,
    PayloadAction<{
        type: FloorPlanDataType, id: string, name: string,
    }>
> = (state, { payload }) => {
    const { type, id, name } = payload

    if (!state.floorPlan)
        return

    state.saveState = "CHANGES_TO_SAVE"

    switch (type) {
        case "Light":
            const light = state.floorPlan?.lights[id]
            if (!light) {
                console.warn("no light with id " + id + " found")
                return
            }

            light.name = name
            state.floorPlan.lights = {
                ...state.floorPlan.lights,
                [id]: light
            }
            break
        case "Spot":
            const spot = state.floorPlan?.spots[id]
            if (!spot) {
                console.warn("no light with id " + id + " found")
                return
            }

            spot.name = name
            state.floorPlan.spots = {
                ...state.floorPlan.spots,
                [id]: spot
            }
            break
        case "Wall":
            const wall = state.floorPlan?.walls[id]
            if (!wall) {
                console.warn("no light with id " + id + " found")
                return
            }

            wall.name = name
            state.floorPlan.walls = {
                ...state.floorPlan.walls,
                [id]: wall
            }
            break

    }
}

export const caseUpdatePropertyPosition: CaseReducer<
    FloorPlanState,
    PayloadAction<{
        type: FloorPlanDataType,
        dx: number,
        dy: number,
        id: string
    }>
> = (state, { payload }) => {
    const { type, id, dx, dy } = payload

    if (!state.floorPlan)
        return

    state.saveState = "CHANGES_TO_SAVE"

    // console.log("update position")

    switch (type) {
        case "Light":
            const light = state.floorPlan?.lights[id]
            if (!light) {
                console.warn("no light with id " + id + " found")
                return
            }

            light.checkIfItsTooCloseToWallsAndUpdateWarning(mapWallsToWallsPos(state.floorPlan.walls))

            light.x += dx
            light.y += dy
            state.floorPlan.lights = {
                ...state.floorPlan.lights,
                [id]: light
            }
            break
        case "Spot":
            const spot = state.floorPlan?.spots[id]
            if (!spot) {
                console.warn("no spot with id " + id + " found")
                return
            }

            spot.x += dx
            spot.y += dy
            state.floorPlan.spots = {
                ...state.floorPlan.spots,
                [id]: spot
            }
            break
        case "Entrance":
            const entrance = state.floorPlan?.entrance
            if (!entrance) {
                console.warn("no entrance found")
                return
            }

            entrance.x += dx
            entrance.y += dy
            state.floorPlan.entrance = Object.assign(new Entrance(), entrance)
            break
        case "Wall":
            const wall = state.floorPlan?.walls[id]
            if (!wall) {
                console.warn("no wall with id " + id + " found")
                return
            }

            wall.origin.x += dx
            wall.destiny.x += dx
            wall.origin.y += dy
            wall.destiny.y += dy
            wall.computePropertiesFromPoints()

            state.floorPlan.walls = {
                ...state.floorPlan.walls,
                [id]: wall
            }
            break
        case "WallOrigin":
            const wallO = state.floorPlan?.walls[id]
            if (!wallO) {
                console.warn("no wall with id " + id + " found")
                return
            }

            wallO.origin.x += dx
            wallO.origin.y += dy
            wallO.computePropertiesFromPoints()

            state.floorPlan.walls = {
                ...state.floorPlan.walls,
                [id]: wallO
            }
            break
        case "WallDestiny":
            const wallD = state.floorPlan?.walls[id]
            if (!wallD) {
                console.warn("no wall with id " + id + " found")
                return
            }

            wallD.destiny.x += dx
            wallD.destiny.y += dy
            wallD.computePropertiesFromPoints()

            state.floorPlan.walls = {
                ...state.floorPlan.walls,
                [id]: wallD
            }
            break
        case "Sculpture":
            const sculpture = state.floorPlan?.sculptures[id]
            if (!sculpture) {
                console.warn("no sculpture with id " + id + " found")
                return
            }

            sculpture.x += dx
            sculpture.y += dy
            state.floorPlan.sculptures = {
                ...state.floorPlan.sculptures,
                [id]: sculpture
            }
            break
        case "Artwork":

            const artwork = state.floorPlan?.artworks[id]
            if (!artwork) {
                console.warn("no artwork with id " + id + " found")
                return
            }

            artwork.x += dx
            artwork.y += dy

            state.floorPlan.artworks = {
                ...state.floorPlan.artworks,
                [id]: artwork
            }
            break
    }
}

export const caseSetPropertyX: CaseReducer<
    FloorPlanState,
    PayloadAction<{
        type: FloorPlanDataType, id: string, x: number,
    }>
> = (state, { payload }) => {
    const { type, id, x } = payload

    if (!state.floorPlan)
        return

    state.saveState = "CHANGES_TO_SAVE"

    switch (type) {
        case "Light":
            const light = state.floorPlan?.lights[id]
            if (!light) {
                console.warn("no light with id " + id + " found")
                return
            }

            light.checkIfItsTooCloseToWallsAndUpdateWarning(mapWallsToWallsPos(state.floorPlan.walls))

            light.x = Math.floor(x)
            state.floorPlan.lights = {
                ...state.floorPlan.lights,
                [id]: light
            }

            break
        case "Spot":
            const spot = state.floorPlan?.spots[id]
            if (!spot) {
                console.warn("no spot with id " + id + " found")
                return
            }

            spot.x = x
            state.floorPlan.spots = {
                ...state.floorPlan.spots,
                [id]: spot
            }
            break
        case "Entrance":
            const entrance = state.floorPlan?.entrance
            if (!entrance) {
                console.warn("no entrance found")
                return
            }

            entrance.x = x
            state.floorPlan.entrance = Object.assign(new Entrance(), entrance)
            break
        case "Wall":
            const wall = state.floorPlan?.walls[id]
            if (!wall) {
                console.warn("no wall with id " + id + " found")
                return
            }

            wall.x = x
            wall.computePointsFromProperties()

            state.floorPlan.walls = {
                ...state.floorPlan.walls,
                [id]: wall
            }
            break
        case "WallOrigin":
            const wallOrigin = state.floorPlan?.walls[id]
            if (!wallOrigin) {
                console.warn("no wall with id " + id + " found")
                return
            }

            wallOrigin.origin.x = x

            if (state.floorPlanSettings?.lockDegrees)
                if (rightAngleConditionX(wallOrigin.origin, wallOrigin.destiny))
                    wallOrigin.origin.x = wallOrigin.destiny.x
                else
                    wallOrigin.origin.y = wallOrigin.destiny.y


            wallOrigin.computePropertiesFromPoints()

            state.floorPlan.walls = {
                ...state.floorPlan.walls,
                [id]: wallOrigin
            }
            break
        case "WallDestiny":
            const WallDestiny = state.floorPlan?.walls[id]
            if (!WallDestiny) {
                console.warn("no wall with id " + id + " found")
                return
            }

            WallDestiny.destiny.x = x

            if (state.floorPlanSettings?.lockDegrees)
                if (rightAngleConditionX(WallDestiny.destiny, WallDestiny.origin))
                    WallDestiny.destiny.x = WallDestiny.origin.x
                else
                    WallDestiny.destiny.y = WallDestiny.origin.y


            WallDestiny.computePropertiesFromPoints()

            state.floorPlan.walls = {
                ...state.floorPlan.walls,
                [id]: WallDestiny
            }
            break
        case "Sculpture":
            const sculpture = state.floorPlan?.sculptures[id]
            if (!sculpture) {
                console.warn("no sculpture with id " + id + " found")
                return
            }

            sculpture.x = x

            state.floorPlan.sculptures = {
                ...state.floorPlan.sculptures,
                [id]: sculpture
            }
            break
        case "Artwork":
            const artwork = state.floorPlan?.artworks[id]
            if (!artwork) {
                console.warn("no artwork with id " + id + " found")
                return
            }

            artwork.x = x

            state.floorPlan.artworks = {
                ...state.floorPlan.artworks,
                [id]: artwork
            }
            break
        default:
            console.warn("set propery x of " + type + " not doing anything")

    }
}


export const caseSetPropertyY: CaseReducer<
    FloorPlanState,
    PayloadAction<{
        type: FloorPlanDataType, id: string, y: number,
    }>
> = (state, { payload }) => {
    const { type, id, y } = payload

    if (!state.floorPlan)
        return

    state.saveState = "CHANGES_TO_SAVE"

    switch (type) {
        case "Light":
            const light = state.floorPlan?.lights[id]
            if (!light) {
                console.warn("no light with id " + id + " found")
                return
            }

            light.checkIfItsTooCloseToWallsAndUpdateWarning(mapWallsToWallsPos(state.floorPlan.walls))

            light.y = y
            state.floorPlan.lights = {
                ...state.floorPlan.lights,
                [id]: light
            }
            break
        case "Spot":
            const spot = state.floorPlan?.spots[id]
            if (!spot) {
                console.warn("no spot with id " + id + " found")
                return
            }

            spot.y = y
            state.floorPlan.spots = {
                ...state.floorPlan.spots,
                [id]: spot
            }
            break
        case "Entrance":
            const entrance = state.floorPlan?.entrance
            if (!entrance) {
                console.warn("no entrance found")
                return
            }

            entrance.y = y
            state.floorPlan.entrance = Object.assign(new Entrance(), entrance)
            break

        case "Wall":
            const wall = state.floorPlan?.walls[id]
            if (!wall) {
                console.warn("no wall with id " + id + " found")
                return
            }

            wall.y = y
            wall.computePointsFromProperties()

            // updateAllLightsWarningFromWall(state.floorPlan as FloorPlanMap, wall)

            state.floorPlan.walls = {
                ...state.floorPlan.walls,
                [id]: wall
            }
            break
        case "WallOrigin":
            const wallOrigin = state.floorPlan?.walls[id]
            if (!wallOrigin) {
                console.warn("no wall with id " + id + " found")
                return
            }

            wallOrigin.origin.y = y

            if (state.floorPlanSettings?.lockDegrees)
                if (!rightAngleConditionX(wallOrigin.origin, wallOrigin.destiny))
                    wallOrigin.origin.y = wallOrigin.destiny.y
                else
                    wallOrigin.origin.x = wallOrigin.destiny.x

            wallOrigin.computePropertiesFromPoints()

            state.floorPlan.walls = {
                ...state.floorPlan.walls,
                [id]: wallOrigin
            }
            break
        case "WallDestiny":
            const WallDestiny = state.floorPlan?.walls[id]
            if (!WallDestiny) {
                console.warn("no wall with id " + id + " found")
                return
            }

            WallDestiny.destiny.y = y

            if (state.floorPlanSettings?.lockDegrees)
                if (!rightAngleConditionX(WallDestiny.destiny, WallDestiny.origin))
                    WallDestiny.destiny.y = WallDestiny.origin.y
                else
                    WallDestiny.destiny.x = WallDestiny.origin.x

            WallDestiny.computePropertiesFromPoints()

            state.floorPlan.walls = {
                ...state.floorPlan.walls,
                [id]: WallDestiny
            }
            break
        case "Sculpture":
            const sculpture = state.floorPlan?.sculptures[id]
            if (!sculpture) {
                console.warn("no sculpture with id " + id + " found")
                return
            }

            sculpture.y = y

            state.floorPlan.sculptures = {
                ...state.floorPlan.sculptures,
                [id]: sculpture
            }
            break
        case "Artwork":
            const artwork = state.floorPlan?.artworks[id]
            if (!artwork) {
                console.warn("no artwork with id " + id + " found")
                return
            }

            artwork.y = y

            state.floorPlan.artworks = {
                ...state.floorPlan.artworks,
                [id]: artwork
            }
            break
        default:
            console.warn("set propery x of " + type + " not doing anything")
    }
}




export const caseSetPropertyOrientation: CaseReducer<
    FloorPlanState,
    PayloadAction<{
        type: FloorPlanDataType, id: string, orientation: ComponentOrientations,
    }>
> = (state, { payload }) => {
    const { type, id, orientation } = payload

    if (!state.floorPlan)
        return

    state.saveState = "CHANGES_TO_SAVE"

    switch (type) {
        case "Light":
            const light = state.floorPlan?.lights[id]
            if (!light) {
                console.warn("no light with id " + id + " found")
                return
            }

            light.orientation = orientation
            state.floorPlan.lights = {
                ...state.floorPlan.lights,
                [id]: light
            }
            light.checkIfItsTooCloseToWallsAndUpdateWarning(mapWallsToWallsPos(state.floorPlan.walls))

            break
        case "Spot":
            const spot = state.floorPlan?.spots[id]
            if (!spot) {
                console.warn("no spot with id " + id + " found")
                return
            }

            spot.orientation = orientation
            state.floorPlan.spots = {
                ...state.floorPlan.spots,
                [id]: spot
            }
            break
        case "Entrance":
            const entrance = state.floorPlan?.entrance
            if (!entrance) {
                console.warn("no entrance found")
                return
            }

            entrance.orientation = orientation
            state.floorPlan.entrance = Object.assign(new Entrance(), entrance)
            break
        case "Sculpture":
            const sculpture = state.floorPlan?.sculptures[id]
            if (!sculpture) {
                console.warn("no sculpture with id " + id + " found")
                return
            }

            sculpture.orientation = orientation

            state.floorPlan.sculptures = {
                ...state.floorPlan.sculptures,
                [id]: sculpture
            }
            break
    }
}


export const caseSetPropertyColor: CaseReducer<
    FloorPlanState,
    PayloadAction<{
        type: FloorPlanDataType, id: string, color: string,
    }>
> = (state, { payload }) => {
    const { type, id, color } = payload

    if (!state.floorPlan)
        return

    state.saveState = "CHANGES_TO_SAVE"

    switch (type) {
        case "Light":
            const light = state.floorPlan?.lights[id]
            if (!light) {
                console.warn("no light with id " + id + " found")
                return
            }

            light.color = color
            state.floorPlan.lights = {
                ...state.floorPlan.lights,
                [id]: light
            }
            break
        case "Wall":
            const wall = state.floorPlan?.walls[id]
            if (!wall) {
                console.warn("no wall with id " + id + " found")
                return
            }

            wall.color = color
            state.floorPlan.walls = {
                ...state.floorPlan.walls,
                [id]: wall
            }
            break
    }
}


export const caseSetPropertyWidth: CaseReducer<
    FloorPlanState,
    PayloadAction<{
        type: FloorPlanDataType, id: string, width: number, keepAR?: boolean
    }>
> = (state, { payload }) => {
    const { type, id, width, keepAR } = payload

    if (!state.floorPlan)
        return

    state.saveState = "CHANGES_TO_SAVE"

    switch (type) {
        case "Wall":
            const wall = state.floorPlan?.walls[id]
            if (!wall) {
                console.warn("no wall with id " + id + " found")
                return
            }
            if (width < 0)
                return

            wall.width = width
            wall.computePointsFromProperties()

            state.floorPlan.walls = {
                ...state.floorPlan.walls,
                [id]: wall
            }
            break
        case "Artwork":
            const artwork = state.floorPlan.artworks[id]
            if (!artwork) {
                console.warn("no artwork with id " + id + " found")
                return
            }

            const previousWidth = artwork.width
            artwork.width = width
            if (width < 1)
                artwork.width = 1

            if (keepAR)
                artwork.height = artwork.width * artwork.height / previousWidth

            state.floorPlan.artworks = {
                ...state.floorPlan.artworks,
                [id]: artwork
            }
            break

    }
}


export const caseSetPropertyHeight: CaseReducer<
    FloorPlanState,
    PayloadAction<{
        type: FloorPlanDataType, id: string, height: number, keepAR?: boolean
    }>
> = (state, { payload }) => {
    const { type, id, height, keepAR } = payload

    if (!state.floorPlan)
        return

    state.saveState = "CHANGES_TO_SAVE"

    switch (type) {
        case "Artwork":
            const artwork = state.floorPlan.artworks[id]
            if (!artwork) {
                console.warn("no artwork with id " + id + " found")
                return
            }

            const previousHeight = artwork.height
            artwork.height = height
            if (height < 1)
                artwork.height = 1

            if (keepAR)
                artwork.width = artwork.height * artwork.width / previousHeight

            state.floorPlan.artworks = {
                ...state.floorPlan.artworks,
                [id]: artwork
            }
            break

    }
}

export const caseSetPropertyDeleted: CaseReducer<
    FloorPlanState,
    PayloadAction<{
        type: FloorPlanDataType, id: string, deleted: boolean,
    }>
> = (state, { payload }) => {

    const { type, id, deleted } = payload

    if (!state.floorPlan)
        return

    state.saveState = "CHANGES_TO_SAVE"

    switch (type) {
        case "Light":
            const light = state.floorPlan?.lights[id]
            if (!light) {
                console.warn("no light with id " + id + " found")
                return
            }

            light.deleted = deleted
            state.floorPlan.lights = {
                ...state.floorPlan.lights,
                [id]: light
            }
            break
        case "Spot":
            const spot = state.floorPlan?.spots[id]
            if (!spot) {
                console.warn("no spot with id " + id + " found")
                return
            }

            spot.deleted = deleted
            state.floorPlan.spots = {
                ...state.floorPlan.spots,
                [id]: spot
            }
            break
        case "Entrance":
            break
        case "Wall":
            const wall = state.floorPlan?.walls[id]
            if (!wall) {
                console.warn("no wall with id " + id + " found")
                return
            }

            wall.deleted = deleted
            wall.computePointsFromProperties()

            const allArtworks = Object.entries(state.floorPlan.artworks)
            for (let i = 0; i < allArtworks.length; i++) {
                const [artworkId, artwork] = allArtworks[i];
                if (artwork.wallId && artwork.wallId === wall.mapRefUUID) {
                    artwork.deleted = deleted

                    state.floorPlan.artworks = {
                        ...state.floorPlan.artworks,
                        [artworkId]: artwork
                    }
                }
            }

            state.floorPlan.walls = {
                ...state.floorPlan.walls,
                [id]: wall
            }
            break
        case "Sculpture":
            const sculpture = state.floorPlan?.sculptures[id]
            if (!sculpture) {
                console.warn("no sculpture with id " + id + " found")
                return
            }

            sculpture.deleted = deleted

            state.floorPlan.sculptures = {
                ...state.floorPlan.sculptures,
                [id]: sculpture
            }
            break
        case "Artwork":
            const artwork = state.floorPlan?.artworks[id]
            if (!artwork) {
                console.warn("no artwork with id " + id + " found")
                return
            }

            artwork.deleted = deleted

            state.floorPlan.artworks = {
                ...state.floorPlan.artworks,
                [id]: artwork
            }
            break
        default:
            console.warn("set propery deleted of " + type + " not doing anything")

    }
}

export const caseSetPropertyUuid: CaseReducer<
    FloorPlanState,
    PayloadAction<{
        type: FloorPlanDataType, id: string, uuid: string,
    }>
> = (state, { payload }) => {

    const { type, id, uuid } = payload

    if (!state.floorPlan)
        return

    state.saveState = "CHANGES_TO_SAVE"

    switch (type) {
        case "Light":
            const light = state.floorPlan?.lights[id]
            if (!light) {
                console.warn("no light with id " + id + " found")
                return
            }

            light.uuid = uuid
            state.floorPlan.lights = {
                ...state.floorPlan.lights,
                [id]: light
            }
            break
        case "Spot":
            const spot = state.floorPlan?.spots[id]
            if (!spot) {
                console.warn("no spot with id " + id + " found")
                return
            }

            spot.uuid = uuid
            state.floorPlan.spots = {
                ...state.floorPlan.spots,
                [id]: spot
            }
            break
        case "Wall":
            const wall = state.floorPlan?.walls[id]
            if (!wall) {
                console.warn("no wall with id " + id + " found")
                return
            }

            wall.uuid = uuid
            wall.computePointsFromProperties()

            state.floorPlan.walls = {
                ...state.floorPlan.walls,
                [id]: wall
            }
            break
        case "Sculpture":
            const sculpture = state.floorPlan?.sculptures[id]
            if (!sculpture) {
                console.warn("no sculpture with id " + id + " found")
                return
            }

            sculpture.uuid = uuid

            state.floorPlan.sculptures = {
                ...state.floorPlan.sculptures,
                [id]: sculpture
            }
            break
        default:
            console.warn("set propery deleted of " + type + " not doing anything")

    }
}

// LIGHT SPECIFIC
export const caseSetPropertyIntensity: CaseReducer<
    FloorPlanState,
    PayloadAction<{
        type: FloorPlanDataType, id: string, intensity: number,
    }>
> = (state, { payload }) => {
    const { type, id, intensity } = payload

    if (!state.floorPlan)
        return

    state.saveState = "CHANGES_TO_SAVE"

    switch (type) {
        case "Light":
            const light = state.floorPlan?.lights[id]
            if (!light) {
                console.warn("no light with id " + id + " found")
                return
            }

            light.intensity = intensity
            if (intensity < 0)
                light.intensity = 0
            light.checkIfItsTooCloseToWallsAndUpdateWarning(mapWallsToWallsPos(state.floorPlan.walls))

            state.floorPlan.lights = {
                ...state.floorPlan.lights,
                [id]: light
            }
            break
    }
}

export const caseSetPropertyOpeningAngle: CaseReducer<
    FloorPlanState,
    PayloadAction<{
        type: FloorPlanDataType, id: string, openingAngle: number,
    }>
> = (state, { payload }) => {
    const { type, id, openingAngle } = payload

    if (!state.floorPlan)
        return

    state.saveState = "CHANGES_TO_SAVE"

    switch (type) {
        case "Light":
            const light = state.floorPlan?.lights[id]
            if (!light) {
                console.warn("no light with id " + id + " found")
                return
            }

            light.openingAngle = openingAngle
            state.floorPlan.lights = {
                ...state.floorPlan.lights,
                [id]: light
            }
            break
    }
}

export const caseSetPropertyBlurryHaloPr: CaseReducer<
    FloorPlanState,
    PayloadAction<{
        type: FloorPlanDataType, id: string, blurryHaloPr: number,
    }>
> = (state, { payload }) => {
    const { type, id, blurryHaloPr } = payload

    if (!state.floorPlan)
        return

    state.saveState = "CHANGES_TO_SAVE"

    switch (type) {
        case "Light":
            const light = state.floorPlan?.lights[id]
            if (!light) {
                console.warn("no light with id " + id + " found")
                return
            }

            light.blurryHaloPr = blurryHaloPr
            state.floorPlan.lights = {
                ...state.floorPlan.lights,
                [id]: light
            }
            break
    }
}

export const caseSetPropertyVerticalAngle: CaseReducer<
    FloorPlanState,
    PayloadAction<{
        type: FloorPlanDataType, id: string, verticalAngle: number,
    }>
> = (state, { payload }) => {
    const { type, id, verticalAngle } = payload

    if (!state.floorPlan)
        return

    state.saveState = "CHANGES_TO_SAVE"

    switch (type) {
        case "Light":
            const light = state.floorPlan?.lights[id]
            if (!light) {
                console.warn("no light with id " + id + " found")
                return
            }

            light.verticalAngle = verticalAngle
            state.floorPlan.lights = {
                ...state.floorPlan.lights,
                [id]: light
            }
            break
    }
}

export const caseComputePropertyWarning: CaseReducer<
    FloorPlanState,
    PayloadAction<{
        type: FloorPlanDataType, id: string,
    }>
> = (state, { payload }) => {
    const { type, id } = payload

    if (!state.floorPlan)
        return

    state.saveState = "CHANGES_TO_SAVE"

    switch (type) {
        case "Light":
            const light = state.floorPlan?.lights[id]
            if (!light) {
                console.warn("no light with id " + id + " found")
                return
            }

            light.checkIfItsTooCloseToWallsAndUpdateWarning(mapWallsToWallsPos(state.floorPlan.walls))
            state.floorPlan.lights = {
                ...state.floorPlan.lights,
                [id]: light
            }
            break
    }
}

// SPOT SPECIFIC
export const caseSetPorpertySpotType: CaseReducer<
    FloorPlanState,
    PayloadAction<{
        type: FloorPlanDataType, id: string, spotType: SpotTypesEnum,
    }>
> = (state, { payload }) => {
    const { type, id, spotType } = payload

    if (!state.floorPlan)
        return

    state.saveState = "CHANGES_TO_SAVE"

    switch (type) {
        case "Spot":
            const spot = state.floorPlan?.spots[id]
            if (!spot) {
                console.warn("no spot with id " + id + " found")
                return
            }

            spot.type = spotType
            state.floorPlan.spots = {
                ...state.floorPlan.spots,
                [id]: spot
            }
            break
    }
}

export const caseSetPropertySpotArtworkUuid: CaseReducer<
    FloorPlanState,
    PayloadAction<{
        type: FloorPlanDataType, id: string, artworkUuid: string,
    }>
> = (state, { payload }) => {
    const { type, id, artworkUuid } = payload

    if (!state.floorPlan)
        return

    state.saveState = "CHANGES_TO_SAVE"

    switch (type) {
        case "Spot":
            const spot = state.floorPlan?.spots[id]
            if (!spot) {
                console.warn("no spot with id " + id + " found")
                return
            }

            spot.artworkUUID = artworkUuid

            state.floorPlan.spots = {
                ...state.floorPlan.spots,
                [id]: spot
            }
            break
    }
}


// WALL SPECIFIC
export const caseSetPropertyAngle: CaseReducer<
    FloorPlanState,
    PayloadAction<{
        type: FloorPlanDataType, id: string, angle: number,
    }>
> = (state, { payload }) => {
    const { type, id, angle } = payload

    if (!state.floorPlan)
        return

    state.saveState = "CHANGES_TO_SAVE"

    switch (type) {
        case "Wall":
            const wall = state.floorPlan?.walls[id]
            if (!wall) {
                console.warn("no wall with id " + id + " found")
                return
            }

            var computedAngle = angle % 360
            if (angle < -180)
                computedAngle = angle + 360
            if (angle > 180)
                computedAngle = angle - 360

            wall.angle = computedAngle
            wall.computePointsFromProperties()

            state.floorPlan.walls = {
                ...state.floorPlan.walls,
                [id]: wall
            }
            break
    }
}


export const caseSetPropertyHasColorBack: CaseReducer<
    FloorPlanState,
    PayloadAction<{
        type: FloorPlanDataType, id: string, hasColorBack: boolean,
    }>
> = (state, { payload }) => {
    const { type, id, hasColorBack } = payload

    if (!state.floorPlan)
        return

    state.saveState = "CHANGES_TO_SAVE"

    switch (type) {
        case "Wall":
            const wall = state.floorPlan?.walls[id]
            if (!wall) {
                console.warn("no wall with id " + id + " found")
                return
            }

            wall.hasColorBack = hasColorBack
            wall.computePointsFromProperties()

            state.floorPlan.walls = {
                ...state.floorPlan.walls,
                [id]: wall
            }
            break
    }
}


export const caseSetPropertyColorBack: CaseReducer<
    FloorPlanState,
    PayloadAction<{
        type: FloorPlanDataType, id: string, color: string,
    }>
> = (state, { payload }) => {
    const { type, id, color } = payload

    if (!state.floorPlan)
        return

    state.saveState = "CHANGES_TO_SAVE"

    switch (type) {
        case "Wall":
            const wall = state.floorPlan?.walls[id]
            if (!wall) {
                console.warn("no wall with id " + id + " found")
                return
            }

            wall.colorBack = color
            state.floorPlan.walls = {
                ...state.floorPlan.walls,
                [id]: wall
            }
            break
    }
}

export const caseSetPropertyThickness: CaseReducer<
    FloorPlanState,
    PayloadAction<{
        type: FloorPlanDataType, id: string, thickness: number,
    }>
> = (state, { payload }) => {
    const { type, id, thickness } = payload

    if (!state.floorPlan)
        return

    state.saveState = "CHANGES_TO_SAVE"

    switch (type) {
        case "Wall":
            const wall = state.floorPlan?.walls[id]
            if (!wall) {
                console.warn("no wall with id " + id + " found")
                return
            }

            wall.thickness = thickness
            if (wall.thickness < 10)
                wall.thickness = 10
            if (wall.thickness > 100)
                wall.thickness = 100

            state.floorPlan.walls = {
                ...state.floorPlan.walls,
                [id]: wall
            }
            break
    }
}

export const caseSetPropertyRegion: CaseReducer<
    FloorPlanState,
    PayloadAction<{
        type: FloorPlanDataType, id: string, region: number,
    }>
> = (state, { payload }) => {
    const { type, id, region } = payload

    if (!state.floorPlan)
        return

    state.saveState = "CHANGES_TO_SAVE"

    switch (type) {
        case "Wall":
            const wall = state.floorPlan?.walls[id]
            if (!wall) {
                console.warn("no wall with id " + id + " found")
                return
            }

            wall.region = region
            if (wall.region < 0)
                wall.region = 0
            if (wall.region > MAX_GROUND_REGIONS)
                wall.region = MAX_GROUND_REGIONS

            state.floorPlan.walls = {
                ...state.floorPlan.walls,
                [id]: wall
            }
            break
    }
}

// Scultpures
export const caseSetPropertyDimensionWidth: CaseReducer<
    FloorPlanState,
    PayloadAction<{
        type: FloorPlanDataType, id: string, width: number,
    }>
> = (state, { payload }) => {
    const { type, id, width } = payload

    if (!state.floorPlan)
        return

    state.saveState = "CHANGES_TO_SAVE"

    switch (type) {
        case "Sculpture":
            const sculpture = state.floorPlan.sculptures[id]
            if (!sculpture) {
                console.warn("no sculpture with id " + id + " found")
                return
            }

            sculpture.dimensions.width = width
            if (width < 0)
                sculpture.dimensions.width = 0

            state.floorPlan.sculptures = {
                ...state.floorPlan.sculptures,
                [id]: sculpture
            }
            break
    }
}

export const caseSetPropertyDimensionDepth: CaseReducer<
    FloorPlanState,
    PayloadAction<{
        type: FloorPlanDataType, id: string, depth: number,
    }>
> = (state, { payload }) => {
    const { type, id, depth } = payload

    if (!state.floorPlan)
        return

    state.saveState = "CHANGES_TO_SAVE"

    switch (type) {
        case "Sculpture":
            const sculpture = state.floorPlan.sculptures[id]
            if (!sculpture) {
                console.warn("no sculpture with id " + id + " found")
                return
            }

            sculpture.dimensions.depth = depth
            if (depth < 0)
                sculpture.dimensions.depth = 0

            state.floorPlan.sculptures = {
                ...state.floorPlan.sculptures,
                [id]: sculpture
            }
            break
    }
}

export const caseSetPropertyDimensionHeight: CaseReducer<
    FloorPlanState,
    PayloadAction<{
        type: FloorPlanDataType, id: string, height: number,
    }>
> = (state, { payload }) => {
    const { type, id, height } = payload

    if (!state.floorPlan)
        return

    state.saveState = "CHANGES_TO_SAVE"

    switch (type) {
        case "Sculpture":
            const sculpture = state.floorPlan.sculptures[id]
            if (!sculpture) {
                console.warn("no sculpture with id " + id + " found")
                return
            }

            sculpture.dimensions.height = height
            if (height < 0)
                sculpture.dimensions.height = 0

            state.floorPlan.sculptures = {
                ...state.floorPlan.sculptures,
                [id]: sculpture
            }
            break
    }
}

export const caseSetPropertyArtowrkWall: CaseReducer<
    FloorPlanState,
    PayloadAction<{
        type: FloorPlanDataType, id: string, wallId: string, side: WallSide
    }>
> = (state, { payload }) => {
    const { type, id, wallId, side } = payload

    if (!state.floorPlan)
        return

    state.saveState = "CHANGES_TO_SAVE"

    switch (type) {
        case "Artwork":
            const artwork = state.floorPlan.artworks[id]
            if (!artwork) {
                console.warn("no artwork with id " + id + " found")
                return
            }

            artwork.wallId = wallId
            artwork.side = side
            state.floorPlan.artworks = {
                ...state.floorPlan.artworks,
                [id]: artwork
            }
            break
    }
}
export const caseSetPropertyHasFrame: CaseReducer<
    FloorPlanState,
    PayloadAction<{
        type: FloorPlanDataType, id: string, hasFrame: boolean,
    }>
> = (state, { payload }) => {
    const { type, id, hasFrame } = payload

    if (!state.floorPlan)
        return

    state.saveState = "CHANGES_TO_SAVE"

    switch (type) {
        case "Artwork":
            const artwork = state.floorPlan.artworks[id]
            if (!artwork) {
                console.warn("no artwork with id " + id + " found")
                return
            }

            artwork.hasFrame = hasFrame
            if (hasFrame) {
                artwork.frameWidth = 5
            } else {
                artwork.passepartoutWidth = 0
                artwork.frameWidth = 0
            }

            state.floorPlan.artworks = {
                ...state.floorPlan.artworks,
                [id]: artwork
            }
            break
    }
}

export const caseSetPropertyImageUrl: CaseReducer<
    FloorPlanState,
    PayloadAction<{
        type: FloorPlanDataType, id: string, imageURL: string,
    }>
> = (state, { payload }) => {
    const { type, id, imageURL } = payload

    if (!state.floorPlan)
        return

    state.saveState = "CHANGES_TO_SAVE"

    switch (type) {
        case "Artwork":
            const artwork = state.floorPlan.artworks[id]
            if (!artwork) {
                console.warn("no artwork with id " + id + " found")
                return
            }

            artwork.imageURL = imageURL
            state.floorPlan.artworks = {
                ...state.floorPlan.artworks,
                [id]: artwork
            }
            break
    }
}

export const caseSetPropertyTitle: CaseReducer<
    FloorPlanState,
    PayloadAction<{
        type: FloorPlanDataType, id: string, title: string,
    }>
> = (state, { payload }) => {
    const { type, id, title } = payload

    if (!state.floorPlan)
        return

    state.saveState = "CHANGES_TO_SAVE"

    switch (type) {
        case "Artwork":
            const artwork = state.floorPlan.artworks[id]
            if (!artwork) {
                console.warn("no artwork with id " + id + " found")
                return
            }

            artwork.title = title
            state.floorPlan.artworks = {
                ...state.floorPlan.artworks,
                [id]: artwork
            }
            break
    }
}


export const caseSetPropertyFrameWidth: CaseReducer<
    FloorPlanState,
    PayloadAction<{
        type: FloorPlanDataType, id: string, frameWidth: number,
    }>
> = (state, { payload }) => {
    const { type, id, frameWidth } = payload

    if (!state.floorPlan)
        return

    state.saveState = "CHANGES_TO_SAVE"

    switch (type) {
        case "Artwork":
            const artwork = state.floorPlan.artworks[id]
            if (!artwork) {
                console.warn("no artwork with id " + id + " found")
                return
            }

            artwork.frameWidth = frameWidth
            if (frameWidth < 0)
                artwork.frameWidth = 0

            state.floorPlan.artworks = {
                ...state.floorPlan.artworks,
                [id]: artwork
            }
            break
    }
}
export const caseSetPropertyDepth: CaseReducer<
    FloorPlanState,
    PayloadAction<{
        type: FloorPlanDataType, id: string, depth: number,
    }>
> = (state, { payload }) => {
    const { type, id, depth } = payload

    if (!state.floorPlan)
        return

    state.saveState = "CHANGES_TO_SAVE"

    switch (type) {
        case "Artwork":
            const artwork = state.floorPlan.artworks[id]
            if (!artwork) {
                console.warn("no artwork with id " + id + " found")
                return
            }

            artwork.depth = depth
            if (depth < 0)
                artwork.depth = 0

            state.floorPlan.artworks = {
                ...state.floorPlan.artworks,
                [id]: artwork
            }
            break
    }
}


export const caseSetPropertyFrameColor: CaseReducer<
    FloorPlanState,
    PayloadAction<{
        type: FloorPlanDataType, id: string, frameColor: string,
    }>
> = (state, { payload }) => {
    const { type, id, frameColor } = payload

    if (!state.floorPlan)
        return

    state.saveState = "CHANGES_TO_SAVE"

    switch (type) {
        case "Artwork":
            const artwork = state.floorPlan.artworks[id]
            if (!artwork) {
                console.warn("no artwork with id " + id + " found")
                return
            }

            artwork.frameColor = frameColor
            state.floorPlan.artworks = {
                ...state.floorPlan.artworks,
                [id]: artwork
            }
            break
    }
}
export const caseSetPropertyEdgeColor: CaseReducer<
    FloorPlanState,
    PayloadAction<{
        type: FloorPlanDataType, id: string, edgeColor: string,
    }>
> = (state, { payload }) => {
    const { type, id, edgeColor } = payload

    if (!state.floorPlan)
        return

    state.saveState = "CHANGES_TO_SAVE"

    switch (type) {
        case "Artwork":
            const artwork = state.floorPlan.artworks[id]
            if (!artwork) {
                console.warn("no artwork with id " + id + " found")
                return
            }

            artwork.edgeColor = edgeColor
            state.floorPlan.artworks = {
                ...state.floorPlan.artworks,
                [id]: artwork
            }
            break
    }
}
export const caseSetPropertyPassepartoutColor: CaseReducer<
    FloorPlanState,
    PayloadAction<{
        type: FloorPlanDataType, id: string, passepartoutColor: string,
    }>
> = (state, { payload }) => {
    const { type, id, passepartoutColor } = payload

    if (!state.floorPlan)
        return

    state.saveState = "CHANGES_TO_SAVE"

    switch (type) {
        case "Artwork":
            const artwork = state.floorPlan.artworks[id]
            if (!artwork) {
                console.warn("no artwork with id " + id + " found")
                return
            }

            artwork.passepartoutColor = passepartoutColor
            state.floorPlan.artworks = {
                ...state.floorPlan.artworks,
                [id]: artwork
            }
            break
    }
}

export const caseSetPropertyPassepartoutWidth: CaseReducer<
    FloorPlanState,
    PayloadAction<{
        type: FloorPlanDataType, id: string, passepartoutWidth: number,
    }>
> = (state, { payload }) => {
    const { type, id, passepartoutWidth } = payload

    if (!state.floorPlan)
        return

    state.saveState = "CHANGES_TO_SAVE"

    switch (type) {
        case "Artwork":
            const artwork = state.floorPlan.artworks[id]
            if (!artwork) {
                console.warn("no artwork with id " + id + " found")
                return
            }

            artwork.passepartoutWidth = passepartoutWidth
            if (passepartoutWidth < 0)
                artwork.passepartoutWidth = 0

            state.floorPlan.artworks = {
                ...state.floorPlan.artworks,
                [id]: artwork
            }
            break
    }
}
export const caseSetPropertyFrameMaterial: CaseReducer<
    FloorPlanState,
    PayloadAction<{
        type: FloorPlanDataType, id: string, frameMaterial: ArtworkFrameMatterial,
    }>
> = (state, { payload }) => {
    const { type, id, frameMaterial } = payload

    if (!state.floorPlan)
        return

    state.saveState = "CHANGES_TO_SAVE"

    switch (type) {
        case "Artwork":
            const artwork = state.floorPlan.artworks[id]
            if (!artwork) {
                console.warn("no artwork with id " + id + " found")
                return
            }

            artwork.frameMaterial = frameMaterial
            state.floorPlan.artworks = {
                ...state.floorPlan.artworks,
                [id]: artwork
            }
            break
    }
}



