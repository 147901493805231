import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import cookie from 'js-cookie';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { RootState } from '..';
import { AuthStatus } from '../../types/Auth.types';
import { SelectedData } from '../../types/Editor.types';
import { ToolType } from '../../types/Editor/Tool.types';
import { FloorPlanArtwork } from '../../types/FloorPlan/Artwork.types';
import { Entrance } from '../../types/FloorPlan/Entrance.types';
import { EYE_LINE_HEIGHT_CM_MAX, EYE_LINE_HEIGHT_CM_MIN, FloorPlanDataType, FloorPlanMap, FloorPlanSettings, FloorPlanState } from '../../types/FloorPlan/FloorPlan.types';
import { FloorPlanData } from '../../types/FloorPlan/FloorPlanElement.types';
import { Light } from '../../types/FloorPlan/Light.types';
import { Sculpture } from '../../types/FloorPlan/Sculpture.types';
import { Spot } from '../../types/FloorPlan/Spot.types';
import { Wall } from '../../types/FloorPlan/Wall.types';
import { callApi } from '../api';
// import { callApi } from '../api';
import { cleanUpUnplacedData, getFloorPlanRequest, removeFloorPlanData, saveFloorPlanRequest, setFloorPlanMap, setFloorPlanSettings, setPropertyUuid, setSaveState, startSaveFloorPlanCycle, tryDeleteData } from '../reducers/FloorPlan/FloorPlan';
import { confirmDeleteModalOpen } from '../reducers/UI';
import { isSuccessResponse } from '../utils';
import missingImage from './../../assets/img/missing-image.png'
import { setExhibitionStatus } from '../reducers/Exhibition';
import { updateAllLightsWarning } from '../reducers/FloorPlan/Properties';


export function* createNewFloorPlanData(data: FloorPlanDataType, id?: string) {
    switch (data) {
        case "Spot":
            const allSpots: { [id: string]: Spot } | undefined =
                yield select((state: RootState) => state.floorPlan.floorPlan?.spots)

            const newSpot = new Spot(id)

            if (allSpots)
                newSpot.name = "Spot " + (Object.keys(allSpots).length + 1)

            return newSpot
        case "Light":
            const allLights: { [id: string]: Light } | undefined =
                yield select((state: RootState) => state.floorPlan.floorPlan?.lights)

            const newLight = new Light(id)

            if (allLights)
                newLight.name = "Light " + (Object.keys(allLights).length + 1)

            return newLight
        case "Sculpture":
            return new Sculpture(id)
        case "Artwork":
            return new FloorPlanArtwork(id)
        case "Wall":

            const allWalls: { [id: string]: Wall } | undefined =
                yield select((state: RootState) => state.floorPlan.floorPlan?.walls)

            const newWall = new Wall(id)

            if (allWalls)
                newWall.name = "Wall " + (Object.keys(allWalls).length + 1)

            return newWall
        default:
            console.warn("createNewFloorPlanData of type " + data + " not developed")
    }
}


function* _cleanUpUnplacedData({ payload: cleanUpType }: PayloadAction<FloorPlanDataType | "All">
) {

    const floorPlanData: FloorPlanMap | null = yield select((state: RootState) => state.floorPlan.floorPlan)
    if (!floorPlanData)
        return

    var cleanUpData: { [id: string]: FloorPlanData } = {}

    switch (cleanUpType) {
        case "Spot":
            cleanUpData = { ...cleanUpData, ...floorPlanData.spots }
            break
        case "Light":
            cleanUpData = { ...cleanUpData, ...floorPlanData.lights }
            break
        case "Sculpture":
            cleanUpData = { ...cleanUpData, ...floorPlanData.sculptures }
            break
        case "Wall":
            cleanUpData = { ...cleanUpData, ...floorPlanData.walls }
            break
        case "All":
            yield put(cleanUpUnplacedData("Light"))
            yield put(cleanUpUnplacedData("Spot"))
            yield put(cleanUpUnplacedData("Sculpture"))
            yield put(cleanUpUnplacedData("Wall"))
            return
        default:
            console.warn("_cleanUpUnplacedData " + cleanUpType + " not developed")
    }

    const entriesToCleanUp = Object.entries(cleanUpData)

    for (let i = 0; i < entriesToCleanUp.length; i++) {
        const [k, v] = entriesToCleanUp[i];

        if (!v.placed) {
            yield put(removeFloorPlanData({ type: cleanUpType, id: k }))
        }
    }
}


export function* watchCleanUpUnplacedData() {
    yield takeLatest(cleanUpUnplacedData, _cleanUpUnplacedData);
}


const mapArtworksWithExhibitionData = (wallsRaw: any) => {
    const artworks: { [id: string]: FloorPlanArtwork } = {}

    for (let i = 0; i < wallsRaw.length; i++) {
        const artworkRaw = wallsRaw[i].artwork
        const wallId = wallsRaw[i].map_ref_uuid

        for (let i = 0; i < artworkRaw.length; i++) {
            const item = artworkRaw[i];
            const artwork: FloorPlanArtwork = new FloorPlanArtwork(item.uuid)

            artwork.x = Number(item.artwork_in_exhibition.x)
            artwork.y = Number(item.artwork_in_exhibition.y)

            artwork.width = Number(item.artwork_in_exhibition.width_in_exhibition)
            artwork.height = Number(item.artwork_in_exhibition.height_in_exhibition)

            artwork.side = item.artwork_in_exhibition.side || 'right'

            artwork.hasFrame = item.artwork_in_exhibition.has_frame === 1
            artwork.frameWidth = Number(item.artwork_in_exhibition.frame_width)
            artwork.frameColor = item.artwork_in_exhibition.frame_color
            artwork.depth = Number(item.artwork_in_exhibition.frame_depth)
            if (item.artwork_in_exhibition.edge_color)
                artwork.edgeColor = item.artwork_in_exhibition.edge_color
            artwork.frameTextureId = item.artwork_in_exhibition.frame_texture_id
            artwork.frameMaterial = item.artwork_in_exhibition.frame_material

            artwork.passepartoutWidth = Number(item.artwork_in_exhibition.passepartout_width)
            artwork.passepartoutColor = item.artwork_in_exhibition.passepartout_color

            artwork.wallId = wallId

            artwork.imageURL = item.photo ? item.photo.medium : missingImage

            if (item.title?.en_EN)
                artwork.title = item.title?.en_EN

            artworks[item.uuid] = artwork
        }
    }

    return artworks
}

const mapWallRawData = (wallsRaw: any) => {
    const walls: { [id: string]: Wall } = {}

    for (let i = 0; i < wallsRaw.length; i++) {
        const item = wallsRaw[i];
        const wall: Wall = new Wall(item.map_ref_uuid)

        wall.name = item.name
        wall.uuid = item.uuid
        wall.origin.x = item.x1
        wall.origin.y = item.y1
        wall.destiny.x = item.x2
        wall.destiny.y = item.y2
        wall.thickness = item.thickness
        wall.color = item.color
        wall.colorBack = item.color_back
        wall.hasColorBack = item.has_color_back === 1
        wall.hasTexture = item.has_texture
        if (typeof (item.region) === 'number')
            wall.region = item.region

        wall.textureURL = item.texture ? item.texture : "EmptyUrlTextureURL"

        wall.computePropertiesFromPoints()

        walls[item.map_ref_uuid] = wall
    }

    return walls
}

const mapSculptureRawData = (sculpturesRaw: any) => {
    const sculptures: { [id: string]: Sculpture } = {}

    for (let i = 0; i < sculpturesRaw.length; i++) {
        const item = sculpturesRaw[i];
        const sculpture: Sculpture = new Sculpture(item.uuid)

        sculpture.uuid = item.uuid
        sculpture.x = Number(item.sculpture_in_exhibition.x)
        sculpture.y = Number(item.sculpture_in_exhibition.y)
        sculpture.z = Number(item.sculpture_in_exhibition.z)
        sculpture.orientation = item.sculpture_in_exhibition.orientation
        sculpture.dimensions = {
            width: Number(item.sculpture_in_exhibition.width_in_exhibition),
            height: Number(item.sculpture_in_exhibition.height_in_exhibition),
            depth: Number(item.sculpture_in_exhibition.depth_in_exhibition),
        }

        // console.log("mapSculptureDataToFloorPlanSculptures", item, sculpture)

        sculptures[item.uuid] = sculpture
    }

    return sculptures
}

const mapLightRawData = (lightsRaw: any) => {
    const lights: { [id: string]: Light } = {}

    for (let i = 0; i < lightsRaw.length; i++) {
        const item = lightsRaw[i]
        const light: Light = new Light(item.map_ref_uuid)

        light.uuid = item.uuid
        light.x = item.x
        light.y = item.y
        light.type = item.type
        light.intensity = item.intensity
        light.color = item.color
        light.orientation = item.orientation
        light.name = item.name
        light.openingAngle = item.opening_angle
        light.blurryHaloPr = item.blurry_halo_pr
        light.verticalAngle = item.vertical_angle

        // console.log("mapLightDataToFloorPlanLights", item, light)
        lights[item.map_ref_uuid] = light
    }

    return lights
}

const mapSpotRawData = (spotsRaw: any) => {
    const spots: { [id: string]: Spot } = {}

    for (let i = 0; i < spotsRaw.length; i++) {
        const item = spotsRaw[i]
        const spot: Spot = new Spot(item.map_ref_uuid)

        spot.uuid = item.uuid
        spot.x = item.x
        spot.y = item.y
        spot.type = item.type
        spot.orientation = item.orientation
        spot.name = item.name
        spot.artworkUUID = item.artwork_uuid

        // console.log("mapSpotDataToFloorPlanSpots", item, spot)
        spots[item.map_ref_uuid] = spot
    }

    return spots
}


const mapEntranceDataToFloorPlanEntrance = (exhibitionData: any): Entrance => {
    const entrance = new Entrance()
    if (exhibitionData.entrance_x !== null)
        entrance.x = exhibitionData.entrance_x

    if (exhibitionData.entrance_y !== null)
        entrance.y = exhibitionData.entrance_y

    if (exhibitionData.entrance_angle !== null)
        entrance.orientation = exhibitionData.entrance_angle
    return entrance
}

// import { isSuccessResponse } from '../utils';

export function* doGetFloorPlan({ payload: mapUuid }: PayloadAction<string | undefined>) {
    // console.log("doGetFloorPlan", mapUuid)

    try {
        if (!mapUuid) {
            return
        }

        yield put(setExhibitionStatus("Loading"))
        const mapResponse: AxiosResponse = yield call(callApi, 'GET', `/maps/${mapUuid}`)
        if (!isSuccessResponse(mapResponse, true)) {
            console.error(mapResponse)
            yield put(setExhibitionStatus("Error_Uuid"))
            return
        }

        
        const data = mapResponse.data.data

        // console.log("FloorPlanData", JSON.stringify(data))


        var floorPlan: FloorPlanMap = {
            id: mapUuid,
            entrance: mapEntranceDataToFloorPlanEntrance(data.exhibition),
            walls: mapWallRawData(data.walls),
            sculptures: mapSculptureRawData(data.sculptures),
            lights: mapLightRawData(data.lights),
            spots: mapSpotRawData(data.spots),
            artworks: mapArtworksWithExhibitionData(data.walls)
        }

        updateAllLightsWarning(floorPlan)
        // console.log("get floorPlan", floorPlan)

        var eyeLineHeight = Number(cookie.get("eyeLineHeight") || EYE_LINE_HEIGHT_CM_MIN)
        if (eyeLineHeight < EYE_LINE_HEIGHT_CM_MIN)
            eyeLineHeight = EYE_LINE_HEIGHT_CM_MIN
        if (eyeLineHeight > EYE_LINE_HEIGHT_CM_MAX)
            eyeLineHeight = EYE_LINE_HEIGHT_CM_MAX

        var lockDegrees = Boolean(cookie.get("lockDegrees") === "true")

        const wallsBaseboard = data.walls[0]?.baseboard === undefined ? true : data.walls[0]?.baseboard

        const floorPlanSettings: FloorPlanSettings = {
            ceilColor: data.ceil_color,
            floorColor: data.floor_color,
            floorHasTexture: data.floor_has_texture === 1,
            floorTextureId: data.floor_texture.id,
            groundColor: data.ground_color,
            hasCeil: data.has_ceil,
            hasFloor: data.has_floor,
            skyColor: data.sky_color,
            tileSize: data.tile_size,
            wallHeight: data.wall_height,
            ambientLightColor: data.ambient_light_color || "#cccccc",
            // wallIncrement: 10,
            wallsBaseboard: wallsBaseboard,
            wallsDefaultColor: data.walls_default_color,
            wallsDefaultTextureURL: data.walls_default_texture_url,
            wallsHaveDefaultTexture: data.walls_have_default_texture,
            eyeLineHeight,
            lockDegrees,
        }


        yield put(setFloorPlanMap(floorPlan))
        yield put(setFloorPlanSettings(floorPlanSettings))
        yield put(setExhibitionStatus("Success_Map"))
        // console.log("finished")
    } catch (error) {
        if (error instanceof Error) {
            console.error(error.message)
        } else {
            console.error('Unexpected error', error);
        }
        yield put(setExhibitionStatus("Error_Unknown"))
        /* 
        yield put(openNotification('An error occurred when getting exhibition map.', 'error'));
        yield put(errorAction(type, error)); */
    }
}

export function* watchGetFloorPlan() {
    yield takeLatest(getFloorPlanRequest, doGetFloorPlan)
}

function floorPlanArtworksToSaveArtworks(artworks: FloorPlanArtwork[]) {
    return artworks.map((a) => ({
        uuid: a.mapRefUUID,
        position: {
            x: a.x,
            y: a.y,
            side: a.side
        },
        frame: {
            has_frame: a.hasFrame,
            frame_width: a.frameWidth,
            frame_color: a.frameColor,
            frame_depth: a.depth,
            passepartout_width: a.passepartoutWidth,
            passepartout_color: a.passepartoutColor,
            edge_color: a.edgeColor,
            frame_texture_id: a.frameTextureId,
            frame_material: a.frameMaterial
        },
        exhibition_data: {
            width: a.width,
            height: a.height
        },
        remove: a.deleted,
    }))
}

function floorPlanWallsToSaveWalls(floorPlanWalls: Wall[], allArtworks: FloorPlanArtwork[], baseBoard: boolean) {
    return floorPlanWalls.map((w) => ({
        uuid: w.uuid,
        map_ref_uuid: w.mapRefUUID,
        name: w.name,
        x1: w.origin.x,
        x2: w.destiny.x,
        y1: w.origin.y,
        y2: w.destiny.y,
        thickness: w.thickness,
        color: w.color,
        has_color_back: w.hasColorBack,
        color_back: w.colorBack,
        remove: w.deleted,
        baseBoard,
        region: w.region,
        artworks: floorPlanArtworksToSaveArtworks(allArtworks.filter((a) => a.wallId === w.mapRefUUID))
    }))
}

function floorPlanLightsToSaveLights(lights: Light[]) {
    return lights.map((l) => ({
        uuid: l.uuid,
        map_ref_uuid: l.mapRefUUID,
        type: l.type,
        x: l.x,
        y: l.y,
        name: l.name,
        color: l.color,
        intensity: l.intensity,
        orientation: l.orientation,
        opening_angle: l.openingAngle,
        blurry_halo_pr: l.blurryHaloPr,
        vertical_angle: l.verticalAngle,
        remove: l.deleted,
    }))
}

function floorPlanSpotsToSaveSpots(spots: Spot[]) {
    return spots.map((s) => ({
        name: s.name,
        x: s.x,
        y: s.y,
        uuid: s.uuid,
        map_ref_uuid: s.mapRefUUID,
        type: s.type,
        orientation: s.orientation,
        artwork_uuid: s.artworkUUID,
        remove: s.deleted,
    }))
}

function floorPlanSculpturesToSaveSculptures(sculptures: Sculpture[]) {
    return sculptures.map((s) => ({
        x: s.x,
        y: s.y,
        z: s.z,
        uuid: s.uuid,
        map_ref_uuid: s.mapRefUUID,
        orientation: s.orientation,
        width_in_exhibition: s.dimensions.width,
        height_in_exhibition: s.dimensions.height,
        depth_in_exhibition: s.dimensions.depth,
        remove: s.deleted,
    }))
}

function* setFloorPlanUuids(floorPlanData: any, floorplan: FloorPlanMap) {
    const noUuidWalls = Object.values(floorplan.walls).filter(w => w.uuid === null)
    const walls: any[] = floorPlanData.walls
    if (!walls)
        throw new Error("Error saving map.")

    for (let i = 0; i < noUuidWalls.length; i++) {
        const wall = noUuidWalls[i]
        const uuid = walls.find((w) => w.map_ref_uuid === wall.mapRefUUID)?.uuid
        // console.log("setting wall uuid", wall.name, wall.mapRefUUID, uuid)
        if (uuid)
            yield put(setPropertyUuid({ id: wall.mapRefUUID, type: "Wall", uuid }))
    }

    const noUuidSpots = Object.values(floorplan.spots).filter(s => s.uuid === null)
    const spots: any[] = floorPlanData.spots
    if (!spots)
        throw new Error("Error saving map.")

    for (let i = 0; i < noUuidSpots.length; i++) {
        const spot = noUuidSpots[i]
        const uuid = spots.find((w) => w.map_ref_uuid === spot.mapRefUUID)?.uuid
        // console.log("setting wall uuid", spot.name, spot.mapRefUUID, uuid)
        if (uuid)
            yield put(setPropertyUuid({ id: spot.mapRefUUID, type: "Spot", uuid }))
    }

    const noUuidLights = Object.values(floorplan.lights).filter(s => s.uuid === null)
    const lights: any[] = floorPlanData.lights
    if (!lights)
        throw new Error("Error saving map.")

    for (let i = 0; i < noUuidLights.length; i++) {
        const light = noUuidLights[i]
        const uuid = lights.find((w) => w.map_ref_uuid === light.mapRefUUID)?.uuid
        // console.log("setting wall uuid", light.name, light.mapRefUUID, uuid)
        if (uuid)
            yield put(setPropertyUuid({ id: light.mapRefUUID, type: "Light", uuid }))
    }
}

function* doSaveFloorPlan() {
    const floorplanState: FloorPlanState | null = yield select((state: RootState) => state.floorPlan)
    const floorplan = floorplanState?.floorPlan
    const floorplanSettings = floorplanState?.floorPlanSettings

    if (floorplanState === null || floorplanState === undefined ||
        floorplan === null || floorplan === undefined ||
        floorplanSettings === null || floorplanSettings === undefined
    ) {
        // console.log("SAVE FLOOR PLAN REQUEST NO FLOORPLAN")
        yield delay(3000)

        const authStatus: AuthStatus = yield select((state: RootState) => state.auth.status)

        if (authStatus !== "invalid_token")
            yield put(saveFloorPlanRequest())

        return
    }


    try {
        yield put(setSaveState('SAVING'))
        const mapResponse: AxiosResponse = yield call(callApi, 'PUT', `/maps/${floorplan.id}`,
            {
                "walls": floorPlanWallsToSaveWalls(Object.values(floorplan.walls).filter(e => e.placed), Object.values(floorplan.artworks), floorplanSettings.wallsBaseboard),
                "lights": floorPlanLightsToSaveLights(Object.values(floorplan.lights).filter(e => e.placed)),
                "spots": floorPlanSpotsToSaveSpots(Object.values(floorplan.spots).filter(e => e.placed)),
                "sculptures": floorPlanSculpturesToSaveSculptures(Object.values(floorplan.sculptures).filter(e => e.placed)),
                "entrance": {
                    x: floorplan.entrance.x,
                    y: floorplan.entrance.y,
                    angle: floorplan.entrance.orientation
                },
                "has_floor": floorplanSettings.hasFloor,
                "floor_color": floorplanSettings.floorColor,
                "floor_has_texture": floorplanSettings.floorHasTexture,
                "floor_texture_id": floorplanSettings.floorTextureId,
                "ground_color": floorplanSettings.groundColor,
                "has_ceil": floorplanSettings.hasCeil,
                "ceil_color": floorplanSettings.ceilColor,
                "wall_height": floorplanSettings.wallHeight,
                "ambient_light_color": floorplanSettings.ambientLightColor,
            }
        )
        if (!isSuccessResponse(mapResponse, true)) {
            console.error(mapResponse)
            yield put(setSaveState('SAVE_ERROR'))
            throw new Error('Unable to save map.')
        }

        // console.log("SAVE FLOOR PLAN REQUEST GOOD")
        yield put(setSaveState('UP_TO_DATE'))

        // set uuids
        yield setFloorPlanUuids(mapResponse.data.data, floorplan)
    }
    catch (error) {
        yield put(setSaveState('SAVE_ERROR'))

        if (error instanceof Error) {
            console.error(error.message)
        } else {
            console.error('Unexpected error', error);
        }
    }
}

export function* watchSaveFloorPlan() {
    yield takeLatest(saveFloorPlanRequest, doSaveFloorPlan)
}


function* doStartSaveFloorPlanCycle() {
    // console.log("START SAVE CYCLE")

    const floorplanState: FloorPlanState = yield select((state: RootState) => state.floorPlan)

    if (floorplanState.autoSave && (floorplanState.saveState === "CHANGES_TO_SAVE" || floorplanState.saveState === "SAVE_ERROR")) {

        const floorplan = floorplanState?.floorPlan
        const floorplanSettings = floorplanState?.floorPlanSettings

        if (floorplan === null || floorplan === undefined ||
            floorplanSettings === null || floorplanSettings === undefined
        ) {
            // console.log("SAVE FLOOR PLAN REQUEST NO FLOORPLAN")
            yield delay(3000)

            const authStatus: AuthStatus = yield select((state: RootState) => state.auth.status)

            if (authStatus !== "invalid_token")
                yield put(startSaveFloorPlanCycle())

        }

        yield doSaveFloorPlan()
    }
    yield delay(30000)
    yield put(startSaveFloorPlanCycle())
}

export function* watchStartSaveFloorPlanCycle() {
    yield takeLatest(startSaveFloorPlanCycle, doStartSaveFloorPlanCycle)
}

function* doTryDeleteData() {
    const currentTool: ToolType = yield select((state: RootState) => state.editor.currentTool.type)
    if (currentTool !== "Select" && currentTool !== "PlaceArtworks")
        return

    const selectedData: SelectedData[] = yield select((state: RootState) => state.editor.selectedData)


    if (selectedData.length === 0)
        return

    if (currentTool === "PlaceArtworks" && selectedData[0].type === "Wall")
        return

    if (selectedData.length === 1 && selectedData[0].type === "Entrance")
        return

    yield put(confirmDeleteModalOpen())
}

export function* watchTryDeleteData() {
    yield takeLatest(tryDeleteData, doTryDeleteData)
}
