import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FloorPlanArtwork } from "../../../types/FloorPlan/Artwork.types";
import { FloorPlanDataType, FloorPlanMap, FloorPlanSettings, FloorPlanState, SaveState } from "../../../types/FloorPlan/FloorPlan.types";
import { FloorPlanData } from "../../../types/FloorPlan/FloorPlanElement.types";
import { Light } from "../../../types/FloorPlan/Light.types";
import { Sculpture } from "../../../types/FloorPlan/Sculpture.types";
import { Spot } from "../../../types/FloorPlan/Spot.types";
import { Wall } from "../../../types/FloorPlan/Wall.types";
import { caseSetFloorPlanSetting } from "./FloorPlanSettings";
import {
    caseSetPropertyColor,
    caseSetPropertyIntensity,
    caseSetPropertyName,
    caseSetPropertyOpeningAngle,
    caseSetPropertyOrientation,
    caseSetPropertyX,
    caseSetPropertyY,
    caseSetPorpertySpotType,
    caseSetPropertyAngle,
    caseSetPropertyWidth,
    caseSetPropertyHasColorBack,
    caseSetPropertyColorBack,
    caseSetPropertySpotArtworkUuid,
    caseSetPropertyPlaced,
    caseSetPropertyDimensionWidth,
    caseSetPropertyDimensionHeight,
    caseSetPropertyArtowrkWall,
    caseSetPropertyHeight,
    caseSetPropertyHasFrame,
    caseSetPropertyImageUrl,
    caseSetPropertyFrameWidth,
    caseSetPropertyDepth,
    caseSetPropertyFrameColor,
    caseSetPropertyPassepartoutColor,
    caseSetPropertyPassepartoutWidth,
    caseSetPropertyFrameMaterial,
    caseSetPropertyDeleted,
    caseSetPropertyUuid,
    caseSetPropertyEdgeColor,
    caseSetPropertyBlurryHaloPr,
    caseSetPropertyVerticalAngle,
    caseSetPropertyDimensionDepth,
    caseSetPropertyTitle,
    caseSetPropertyThickness,
    caseSetPropertyRegion,
    caseComputePropertyWarning
} from "./Properties"
import Cookies from "js-cookie";

const initialState: FloorPlanState = {
    floorPlan: null,
    floorPlanSettings: null,
    saveState: "UP_TO_DATE",
    autoSave: Cookies.get("autoSave") !== "false",
}

export const floorPlanSlice = createSlice({
    name: "floorPlan",
    initialState,
    reducers: {
        getFloorPlanRequest: (state, action: PayloadAction<string | undefined>) => { },

        startSaveFloorPlanCycle: () => { },
        saveFloorPlanRequest: () => { },

        disableAutoSave: (state) => {
            Cookies.set("autoSave", "false")
            state.autoSave = false
        },
        enableAutoSave: (state) => {
            Cookies.set("autoSave", "true")
            state.autoSave = true
        },

        setFloorPlanMap: (state, { payload }: PayloadAction<FloorPlanMap>) => {
            // console.log("setFloorPlanMap", payload)
            state.floorPlan = payload;
        },
        setFloorPlanSettings: (state, { payload }: PayloadAction<FloorPlanSettings>) => {
            // console.log("setFloorPlanSettings", payload)
            state.floorPlanSettings = payload;
        },
        setFloorPlanSetting: caseSetFloorPlanSetting,

        removeFloorPlanData: (state, { payload }: PayloadAction<{ type: FloorPlanDataType, id: string }>) => {
            if (!state.floorPlan)
                return

            const { id, type } = payload
            switch (type) {
                case "Spot":
                    if (state.floorPlan.spots[id])
                        delete state.floorPlan.spots[id]
                    break
                case "Light":
                    if (state.floorPlan.lights[id])
                        delete state.floorPlan.lights[id]
                    break
                case "Sculpture":
                    if (state.floorPlan.sculptures[id])
                        delete state.floorPlan.sculptures[id]
                    break
                case "Wall":
                    if (state.floorPlan.walls[id])
                        delete state.floorPlan.walls[id]
                    break
                default:
                    console.warn("removeFloorPlanData " + type + " not developed")
            }

        },
        addFloorPlanData: (state, { payload }: PayloadAction<{ id: string, data: FloorPlanData }>) => {
            if (!state.floorPlan)
                return

            const { id, data } = payload

            // console.log("Adding floorplan data", data)
            if (data instanceof Spot)
                state.floorPlan.spots[id] = data
            else if (data instanceof Light)
                state.floorPlan.lights[id] = data
            else if (data instanceof Sculpture)
                state.floorPlan.sculptures[id] = data
            else if (data instanceof FloorPlanArtwork)
                state.floorPlan.artworks[id] = data
            else if (data instanceof Wall)
                state.floorPlan.walls[id] = data
            else
                console.warn("addFloorPlanData of type " + typeof (data) + " not implemented")
        },

        cleanUpUnplacedData: (state, { payload }: PayloadAction<FloorPlanDataType | "All">) => { },

        setPropertyPlaced: caseSetPropertyPlaced,
        setPropertyName: caseSetPropertyName,
        setPropertyX: caseSetPropertyX,
        setPropertyY: caseSetPropertyY,
        setPropertyOrientation: caseSetPropertyOrientation,

        setPropertyColor: caseSetPropertyColor,

        setPropertyWidth: caseSetPropertyWidth,
        setPropertyHeight: caseSetPropertyHeight,

        tryDeleteData: () => { },
        setPropertyDeleted: caseSetPropertyDeleted,
        setPropertyUuid: caseSetPropertyUuid,

        // light
        setPropertyIntensity: caseSetPropertyIntensity,
        setPropertyOpeningAngle: caseSetPropertyOpeningAngle,
        setPropertyBlurryHaloPr: caseSetPropertyBlurryHaloPr,
        setPropertyVerticalAngle: caseSetPropertyVerticalAngle,
        computePropertyWarning: caseComputePropertyWarning,

        // spot
        setPorpertySpotType: caseSetPorpertySpotType,
        setPropertySpotArtworkUuid: caseSetPropertySpotArtworkUuid,

        // wall
        setPropertyAngle: caseSetPropertyAngle,
        setPropertyHasColorBack: caseSetPropertyHasColorBack,
        setPropertyColorBack: caseSetPropertyColorBack,
        setPropertyThickness: caseSetPropertyThickness,
        setPropertyRegion: caseSetPropertyRegion,

        // sculpture
        setPropertyDimensionWidth: caseSetPropertyDimensionWidth,
        setPropertyDimensionDepth: caseSetPropertyDimensionDepth,
        setPropertyDimensionHeight: caseSetPropertyDimensionHeight,

        // artwork
        setPropertyArtowrkWall: caseSetPropertyArtowrkWall,
        setPropertyHasFrame: caseSetPropertyHasFrame,
        setPropertyImageUrl: caseSetPropertyImageUrl,
        setPropertyTitle: caseSetPropertyTitle,
        setPropertyFrameWidth: caseSetPropertyFrameWidth,
        setPropertyDepth: caseSetPropertyDepth,
        setPropertyFrameColor: caseSetPropertyFrameColor,
        setPropertyEdgeColor: caseSetPropertyEdgeColor,
        setPropertyPassepartoutColor: caseSetPropertyPassepartoutColor,
        setPropertyPassepartoutWidth: caseSetPropertyPassepartoutWidth,
        setPropertyFrameMaterial: caseSetPropertyFrameMaterial,

        uploadArtwork: (state, action: PayloadAction<{ artworkUuid: string, file: File }>) => { },

        setSaveState: (state, action: PayloadAction<SaveState>) => {
            state.saveState = action.payload
        },
    }
})

export const {
    getFloorPlanRequest,
    setFloorPlanMap,
    setFloorPlanSettings,
    setFloorPlanSetting,

    startSaveFloorPlanCycle,
    saveFloorPlanRequest,
    disableAutoSave,
    enableAutoSave,

    removeFloorPlanData,
    addFloorPlanData,
    cleanUpUnplacedData,

    setPropertyPlaced,
    setPropertyName,
    setPropertyX,
    setPropertyY,
    setPropertyOrientation,

    setPropertyWidth,
    setPropertyHeight,

    tryDeleteData,
    setPropertyDeleted,
    setPropertyUuid,

    setPropertyColor,

    setPropertyIntensity,
    setPropertyOpeningAngle,
    setPropertyBlurryHaloPr,
    setPropertyVerticalAngle,
    computePropertyWarning,

    setPorpertySpotType,
    setPropertySpotArtworkUuid,

    setPropertyAngle,
    setPropertyHasColorBack,
    setPropertyColorBack,
    setPropertyThickness,
    setPropertyRegion,

    setPropertyDimensionWidth,
    setPropertyDimensionDepth,
    setPropertyDimensionHeight,

    setPropertyArtowrkWall,

    setPropertyHasFrame,
    setPropertyImageUrl,
    setPropertyTitle,
    setPropertyFrameWidth,
    setPropertyDepth,
    setPropertyFrameColor,
    setPropertyEdgeColor,
    setPropertyPassepartoutColor,
    setPropertyPassepartoutWidth,
    setPropertyFrameMaterial,

    uploadArtwork,

    setSaveState,
} = floorPlanSlice.actions

export default floorPlanSlice.reducer