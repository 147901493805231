import { Fragment, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { selectSculputreArtworks } from "../../../store/reducers/Artworks";
import { setPropertyDimensionDepth, setPropertyDimensionHeight, setPropertyDimensionWidth, setPropertyOrientation, setPropertyX, setPropertyY } from "../../../store/reducers/FloorPlan/FloorPlan";
import { confirmDeleteModalOpen } from "../../../store/reducers/UI";
import { ComponentOrientations, ComponentOrientationsToEngText } from "../../../types/FloorPlan/OrientableComponent.types";
import { Sculpture } from "../../../types/FloorPlan/Sculpture.types";
import DataPreviewGrid from "../Components/dataPreviewGrid";
import { DefaultInspectorOptions } from "../Components/defaultInspectorOptions";
import { SculptureButtonInput } from "../Components/sculptureButtonInput";
import { InspectorBody } from "../Layout/inspectorBody";
import { InspectorFooter } from "../Layout/inspectorFooter";
import { ButtonPropertyInput } from "../Properties/ButtonPropertyInput";
import { NumberPropertyInput } from "../Properties/NumberPropertyInput";
import { PropertyInput } from "../Properties/PropertyInput";
import { SelectPropertyInput } from "../Properties/SelectPropertyInput";
import { SubinspectorPropertyInput } from "../Properties/subinspectorPropertyInput";
import { AdvancedSculptureSettingsInspector } from "../advancedSculptureSettingsInspector";

export function SculpturesSelectedInspector() {

    const floorPlanSculptures = useAppSelector(state => state.floorPlan.floorPlan?.sculptures)

    const allSculptures = useAppSelector(selectSculputreArtworks)

    const selectedData = useAppSelector(state => state.editor.selectedData)

    const [sculptures, setSculptures] = useState<Sculpture[]>([])

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (floorPlanSculptures) {
            const newSculptures: Sculpture[] = []
            // console.log("Selected data: ", selectedData, floorPlanSculptures)
            selectedData.filter((e) => e.type === "Sculpture").forEach(element => {
                const sculpture = floorPlanSculptures[element.id]
                if (sculpture)
                    newSculptures.push(sculpture)
                else
                    console.warn(element.id + " not found as a sculpure")
            })
            setSculptures(newSculptures)
        }
    }, [floorPlanSculptures, selectedData])

    return (
        <Fragment>
            <InspectorBody>

                <PropertyInput
                    propertyName="en_EN"
                    label="Name"
                    objects={Object.values(allSculptures).filter((s) => selectedData.map(sd => sd.id).includes(s.uuid)).map(o => o.title)}
                    disabled
                />

                <NumberPropertyInput
                    propertyName={"x"}
                    label="X"
                    objects={sculptures}
                    onValueChange={(x) =>
                        selectedData.forEach(data => {
                            dispatch(setPropertyX({
                                type: data.type,
                                id: data.id,
                                x
                            }))
                        })
                    }
                />
                <NumberPropertyInput
                    propertyName={"y"}
                    label="Y"
                    objects={sculptures}
                    onValueChange={(y) =>
                        selectedData.forEach(data => {
                            dispatch(setPropertyY({
                                type: data.type,
                                id: data.id,
                                y
                            }))
                        })
                    }
                />

                <SelectPropertyInput<ComponentOrientations, "orientation">
                    keyValue={ComponentOrientationsToEngText}
                    propertyName="orientation"
                    label="Orientation"
                    objects={sculptures}
                    onValueChange={(orientation) =>
                        selectedData.forEach(data => {
                            dispatch(setPropertyOrientation({
                                type: data.type,
                                id: data.id,
                                orientation
                            }))
                        })
                    }
                />

                <NumberPropertyInput
                    propertyName={"width"}
                    label="Width"
                    objects={sculptures.map(s => s.dimensions)}
                    onValueChange={(width) =>
                        selectedData.forEach(data => {
                            dispatch(setPropertyDimensionWidth({
                                type: data.type,
                                id: data.id,
                                width
                            }))
                        })
                    }
                    step={1}
                />
                <NumberPropertyInput
                    propertyName={"depth"}
                    label="Depth"
                    objects={sculptures.map(s => s.dimensions)}
                    onValueChange={(depth) =>
                        selectedData.forEach(data => {
                            dispatch(setPropertyDimensionDepth({
                                type: data.type,
                                id: data.id,
                                depth
                            }))
                        })
                    }
                    step={1}
                />
                <NumberPropertyInput
                    propertyName={"height"}
                    label="Height"
                    objects={sculptures.map(s => s.dimensions)}
                    onValueChange={(height) =>
                        selectedData.forEach(data => {
                            dispatch(setPropertyDimensionHeight({
                                type: data.type,
                                id: data.id,
                                height
                            }))
                        })
                    }
                    step={1}
                />

                <SubinspectorPropertyInput header="Advanced settings" sx={{ paddingX: 0 }} headerSx={{ paddingX: "24px" }} >
                    <AdvancedSculptureSettingsInspector />
                </SubinspectorPropertyInput>

                <DataPreviewGrid
                    data={
                        Object.values(allSculptures).filter((s) => selectedData.map(sd => sd.id).includes(s.uuid)).map((a) => {
                            return {
                                id: a.uuid,
                                previewImageURL: a.photo.original,
                                altText: a.title?.en_EN || a.photo.fileName
                            }
                        }
                        )}
                />

            </InspectorBody>
            <InspectorFooter>
                <SculptureButtonInput />
                <ButtonPropertyInput
                    onClick={() => {
                        dispatch(confirmDeleteModalOpen())
                    }}
                    color={"error"}
                    variant={"contained"}
                >
                    <span>Delete</span>
                </ButtonPropertyInput>

                <DefaultInspectorOptions />
            </InspectorFooter>
        </Fragment>
    )
} 