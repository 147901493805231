import * as PIXI from 'pixi.js'
// import faker from "faker";
import { OrientableFloorPlanElement } from "./orientableFloorPlanElement";
import { Sculpture } from '../../../../../types/FloorPlan/Sculpture.types';

export class SculptureElement extends OrientableFloorPlanElement {
    private sculptureDepth: number
    private sculptureWidth: number

    constructor(
        sculpture: Sculpture
    ) {
        super("Sculpture", sculpture.x, sculpture.y, sculpture.mapRefUUID, sculpture.orientation, undefined, true,
            SculptureElement.computeDiagonal(sculpture.dimensions.width, sculpture.dimensions.depth))

        this.sculptureWidth = sculpture.dimensions.width
        this.sculptureDepth = sculpture.dimensions.depth

        const sculptureGraphic = new PIXI.Graphics()

        this.sprite = sculptureGraphic


        this.reDraw()
        this.addChild(this.sprite) // add
        this.setOrientation(sculpture.orientation)
    }

    private static computeDiagonal(height: number, width: number) {
        return Math.sqrt(height * height + width * width) / 2 + 2
    }

    reDraw() {
        super.reDraw()
        if (this.sprite instanceof PIXI.Graphics)
            this.sprite
                .clear()
                .beginFill(0x000000)
                .drawRect(
                    -this.sculptureDepth / 2, -this.sculptureWidth / 2,
                    this.sculptureDepth, this.sculptureWidth)
                .endFill()
    }

    resizeSculpture(depth: number, width: number) {
        if (this.sculptureDepth === depth && this.sculptureWidth === width)
            return

        super.resize(SculptureElement.computeDiagonal(depth, width))

        this.sculptureDepth = depth
        this.sculptureWidth = width

        this.reDraw()
    }

}