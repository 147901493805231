import { InteractionEvent } from "pixi.js";
import { Tool } from "./tool"
import { SculptureElement } from "../components/orientableFloorPlanElements/sculptureElement";
import FloorPlanConnector from "../floorPlanConnector";
import { SelectedData } from "../../../../types/Editor.types";

export class PlaceSculptureTool extends Tool {
    sculptureElement?: SculptureElement

    constructor() {
        super()
        this.toolType = "Sculptures"
    }

    // interaction    
    onDragEnd(e: InteractionEvent): void {
        const sculpture = this.sculptureElement
        if (!sculpture) {
            return
        }

        this.app.addElement(sculpture)

        FloorPlanConnector.elementPlaced("Sculpture", sculpture.mapRefUUID)

        this.sculptureElement = undefined
    }
    
    onDragMove(e: InteractionEvent): void {
        if (!this.sculptureElement)
            return

        const interactionPoint = e.data.getLocalPosition(this.app.floorPlanCanvas)
        FloorPlanConnector.moveElementTo(interactionPoint.x, interactionPoint.y, this.sculptureElement.mapRefUUID, "Sculpture")
    }

    enable() {

    }

    dissable() {
        if (!this.sculptureElement)
            return

        this.app.removeElement(this.sculptureElement)
        this.sculptureElement = undefined
    }

    onSelectChange(selectedData: SelectedData[]): void {
        if (selectedData.length === 0 && this.sculptureElement) {
            this.app.removeElement(this.sculptureElement)
            this.sculptureElement = undefined
        }
    }
}